import {
  ApplicationConfig,
  PLATFORM_ID,
  importProvidersFrom,
  inject,
  isDevMode
} from '@angular/core';
import {
  Params,
  RouterStateSnapshot,
  provideRouter,
  withInMemoryScrolling
} from '@angular/router';

import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { connectAuthEmulator, getAuth, provideAuth } from '@angular/fire/auth';
import {
  connectFirestoreEmulator,
  getFirestore,
  provideFirestore
} from '@angular/fire/firestore';
import {
  connectFunctionsEmulator,
  getFunctions,
  provideFunctions
} from '@angular/fire/functions';
import {
  getRemoteConfig,
  provideRemoteConfig
} from '@angular/fire/remote-config';
import {
  connectStorageEmulator,
  getStorage,
  provideStorage
} from '@angular/fire/storage';
import { provideAnimations } from '@angular/platform-browser/animations';
import { provideServiceWorker } from '@angular/service-worker';
import { provideEffects } from '@ngrx/effects';
import {
  NavigationActionTiming,
  RouterStateSerializer,
  provideRouterStore
} from '@ngrx/router-store';
import { provideStore } from '@ngrx/store';

import { isPlatformBrowser } from '@angular/common';
import { provideHttpClient, withFetch } from '@angular/common/http';
import { getAnalytics, provideAnalytics } from '@angular/fire/analytics';
import {
  provideClientHydration,
  withEventReplay,
  withHttpTransferCacheOptions
} from '@angular/platform-browser';
import { CookieModule } from 'ngx-cookie';
import { environment } from '../environments/environment';
import { routes } from './app.routes';
import { envKeys } from './env.providers';

export interface VakiRouterState {
  url: string;
  params: Params;
  queryParams: Params;
}

class VakiRouterSerializer implements RouterStateSerializer<VakiRouterState> {
  serialize(routerState: RouterStateSnapshot): VakiRouterState {
    let route = routerState.root;

    while (route.firstChild) {
      route = route.firstChild;
    }

    const {
      url,
      root: { queryParams }
    } = routerState;
    const { params } = route;

    // Only return an object including the URL, params and query params
    // instead of the entire snapshot
    return { url, params, queryParams };
  }
}

import { LogLevel, setLogLevel } from '@angular/fire';

setLogLevel(LogLevel.VERBOSE);

export const appConfig: ApplicationConfig = {
  providers: [
    ...envKeys,
    provideClientHydration(
      withEventReplay(),
      withHttpTransferCacheOptions({
        includePostRequests: true
      })
    ),
    provideHttpClient(withFetch()),
    provideServiceWorker('ngsw-worker.js', {
      enabled: !isDevMode(),
      registrationStrategy: 'registerWhenStable:30000'
    }),
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideFirestore(() => {
      const firestore = getFirestore(getApp());
      // Ensure Firestore is only initialized once
      if (environment.useEmulators && isPlatformBrowser(inject(PLATFORM_ID))) {
        connectFirestoreEmulator(firestore, 'localhost', 8080);
      }

      return firestore;
    }),
    provideAuth(() => {
      const auth = getAuth();
      if (environment.useEmulators && isPlatformBrowser(inject(PLATFORM_ID))) {
        connectAuthEmulator(auth, 'http://localhost:9099');
      }
      return auth;
    }),
    provideFunctions(() => {
      const functions = getFunctions(getApp());
      if (environment.useEmulators && isPlatformBrowser(inject(PLATFORM_ID))) {
        connectFunctionsEmulator(functions, 'localhost', 5001);
      }
      return functions;
    }),
    provideStorage(() => {
      const storage = getStorage();
      if (environment.useEmulators && isPlatformBrowser(inject(PLATFORM_ID))) {
        connectStorageEmulator(storage, 'localhost', 9199);
      }
      return storage;
    }),
    provideRemoteConfig(() => getRemoteConfig()),
    provideAnalytics(() => getAnalytics()),
    provideRouter(
      routes,
      withInMemoryScrolling({
        scrollPositionRestoration: 'enabled',
        anchorScrolling: 'enabled'
      })
    ),
    provideStore(
      {},
      {
        runtimeChecks: {
          strictStateImmutability: false,
          strictActionImmutability: false
        }
      }
    ),
    provideRouterStore({
      serializer: VakiRouterSerializer,
      navigationActionTiming: NavigationActionTiming.PostActivation
    }),
    provideEffects(),
    provideAnimations(),
    importProvidersFrom(CookieModule.withOptions({}))
  ]
};
